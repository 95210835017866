import React from 'react';
import classnames from 'classnames';

import { RichText } from '../RichText';
import { parseAttributes, styleBlockFromAttributes } from '../../utils';

import classes from './Columns.module.scss';

export type ColumnsProps = {
    totalColumns?: number;
    innerBlocks?: any;
    attributes?: string;
};

export const Columns = (props: ColumnsProps) => {
    const columnsAttrs = parseAttributes(props?.attributes);
    const columnsStyles = styleBlockFromAttributes(columnsAttrs);

    return (
        <div
            className={classnames({
                [classes.Columns]: true,
                [classes.Columns2]: props?.totalColumns === 2,
                [classes.Columns3]: props?.totalColumns === 3,
                [classes.Columns4]: props?.totalColumns === 4,
                [classes.Columns5]: props?.totalColumns === 5,
                [classes.Columns6]: props?.totalColumns === 6,
                [classes.ColumnsGap]: (props?.totalColumns || 0) > 1,
            })}
            style={columnsStyles}
            data-stack-on-mobile={columnsAttrs?.isStackedOnMobile ? 'true' : 'false'}
        >
            {props.innerBlocks?.map((innerBlock, index) => {
                return <RichText content={innerBlock.innerBlocks} key={`${innerBlock.name}-${index}`} />;
            })}
        </div>
    );
};
