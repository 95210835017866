import React from 'react';
import { decode } from 'html-entities';

import { SingleCategory } from 'src/wordpress/types';

import classes from './HeaderPost.scss';

export type HeaderPostProps = {
    category?: SingleCategory[];
    date?: string;
    title?: string;
    subtitle?: string;
    displayDate?: boolean;
};

export const formatDate = (date: string | number | Date, display = false) => {
    // Exit early if display is false
    if (display) return '';

    // Turn our date into a date object
    const dateAsObject = new Date(date);

    // Allow the date number to have the correct suffix string after it. E.g 2nd, 5th, 3rd etc
    // See: https://stackoverflow.com/a/39466341
    function nth(n) {
        return ['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] || 'th';
    }

    // Months as an array
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    // Set up required elements to create custom date format
    const day = dateAsObject.getDate();
    const month = months[dateAsObject.getMonth()];
    const year = dateAsObject.getFullYear();

    return `${day}${nth(day)} ${month} ${year}`;
};

export const HeaderPost = (props: HeaderPostProps) => {
    const formattedDate = props?.date ? formatDate(props.date) : '';

    return (
        <div className={classes.root}>
            <p className={classes.details}>
                {props?.category?.map((cat, index) => {
                    if (props?.category) {
                        return (
                            <a href={`/community/category/${cat.slug}`} key={`${cat.name}-${index}`}>
                                {decode(cat.name) + (index < props?.category.length - 1 ? ', ' : '')}
                            </a>
                        );
                    }
                })}
                {props?.displayDate && <span className={classes.date}>{formattedDate}</span>}
            </p>
            <h1 className={classes.title}>{decode(props.title)}</h1>
            <h2 className={classes.subtitle}>{decode(props.subtitle)}</h2>
        </div>
    );
};

export default HeaderPost;
