import React from 'react';
import { useParams } from 'react-router';

import { ArchiveProvider, ArchiveFeature } from 'src/wordpress/features/Archive';

export const DefaultArchive = () => {
    const { slug } = useParams() as Record<string, any>;

    return (
        <ArchiveProvider postSlug={slug}>
            <ArchiveFeature />
        </ArchiveProvider>
    );
};

export default DefaultArchive;
