import React, { useContext } from 'react';

import { FrontpageContext, FrontpageUI } from 'src/wordpress/features/Frontpage';

export const FrontpageFeature = () => {
    const {
        isErrorCategories,
        isErrorPosts,
        isErrorSiteData,
        isLoadingCategories,
        isLoadingPosts,
        isLoadingSiteData,
        latestPosts,
        allCategories,
        siteData,
        canonicalURL,
    } = useContext(FrontpageContext);

    return (
        <FrontpageUI
            isErrorPosts={isErrorPosts}
            isErrorCategories={isErrorCategories}
            isErrorSiteData={isErrorSiteData}
            isLoadingPosts={isLoadingPosts}
            isLoadingCategories={isLoadingCategories}
            isLoadingSiteData={isLoadingSiteData}
            latestPosts={latestPosts}
            allCategories={allCategories}
            siteData={siteData}
            canonicalURL={canonicalURL}
        />
    );
};

export default FrontpageFeature;
