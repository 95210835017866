import React from 'react';
import { ApolloError } from 'apollo-client';

import { LoaderMinHeight } from 'src/components/Helpers/Skeleton';
import { ArchivePagination } from 'src/wordpress/components/ArchivePagination';
import { PageData } from 'src/wordpress/types';

import { Header } from '../../components/Header';
import { PostListGrid } from '../../components/PostListGrid';
import { YoastBreadcrumbs } from '../../components/Breadcrumbs';
import { Yoast } from '../../components/Yoast';

export type ArchiveUIProps = {
    isLoading: boolean;
    posts: any;
    archiveData: any;
    error?: ApolloError;
    pageData?: PageData;
    postCount?: number;
    archiveSlug?: string;
    currentPageNumber?: number;
    canonicalURL: string;
};

export const ArchiveUI = (props: ArchiveUIProps) => {
    if (props.isLoading) {
        return <LoaderMinHeight />;
    }

    return (
        <>
            <Yoast
                schema={props?.archiveData[1]?.seo?.schema}
                postTitle={props?.archiveData[1]?.seo?.title}
                metaDesc={props?.archiveData[1]?.seo?.metaDesc}
                metaKeywords={props?.archiveData[1]?.seo?.metaKeywords}
                focusKeywords={props?.archiveData[1]?.seo?.focusKeywords}
                opengraph={{
                    ogTitle: props?.archiveData[1]?.seo?.opengraph?.ogTitle,
                    ogType: props?.archiveData[1]?.seo?.opengraph?.ogType,
                    ogURL: props?.archiveData[1]?.seo?.opengraph?.ogURL,
                    ogDescription: props?.archiveData[1]?.seo?.opengraph?.ogDescription,
                    ogSiteName: props?.archiveData[1]?.seo?.opengraph?.ogSiteName,
                    ogArticlePublished: props?.archiveData[1]?.seo?.opengraph?.ogArticlePublished,
                }}
                canonicalUrl={props?.canonicalURL}
            />

            <YoastBreadcrumbs breadcrumbs={props?.archiveData[1]?.seo?.breadcrumbs} />

            <Header
                variant="archive"
                title={props?.archiveData[0]?.categoryTitle}
                subtitle={props?.archiveData[0]?.categoryDescription}
            />

            <PostListGrid posts={props?.posts} variant="archive" />

            <ArchivePagination
                totalPostsToDisplay={props?.postCount}
                currentArchiveSlug={props?.archiveSlug}
                pageData={props?.pageData}
                currentPageNumber={props?.currentPageNumber}
            />
        </>
    );
};

export default ArchiveUI;
