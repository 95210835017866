import React from 'react';

import { FrontpageContextProvider, FrontpageFeature } from 'src/wordpress/features/Frontpage';

export const Frontpage = () => {
    return (
        <FrontpageContextProvider>
            <FrontpageFeature />
        </FrontpageContextProvider>
    );
};
