export const getSingleOption = (options: string[], optionToFetch: string) => {
    if (options.length > 0) {
        // Options should be unique, so we return the first matching option
        return options.filter((option) => {
            return option.toLowerCase().includes(optionToFetch);
        })[0];
    }

    return undefined;
};

export default getSingleOption;
