import React from 'react';
import classnames from 'classnames';

import { PageData } from 'src/wordpress/types';

import classes from './ArchivePagination.module.scss';

export type ArchivePaginationProps = {
    totalPostsToDisplay?: number;
    currentArchiveSlug?: string;
    currentPageNumber?: number;
    pageData?: PageData;
};

export const ArchivePagination = (props: ArchivePaginationProps) => {
    const currentArchiveSlug = props?.currentArchiveSlug;

    const hasPreviousPosts = props?.pageData?.offsetPagination?.hasPrevious;
    const hasMorePosts = props?.pageData?.offsetPagination?.hasMore;
    const previousPageNumber =
        props?.currentPageNumber && props?.currentPageNumber > 1 ? Number(props?.currentPageNumber - 1) : 1;
    const nextPageNumber =
        props?.currentPageNumber && props?.currentPageNumber >= 1 ? Number(props?.currentPageNumber + 1) : 1;

    const previousPostsHref =
        previousPageNumber === 1
            ? `/community/category/${currentArchiveSlug}`
            : `/community/category/${currentArchiveSlug}/page/${previousPageNumber}`;
    const nextPostsHref = `/community/category/${currentArchiveSlug}/page/${nextPageNumber}`;

    // eslint-disable-next-line react/jsx-no-useless-fragment
    if (!hasMorePosts && !hasPreviousPosts) return <></>;

    return (
        <div className={classes.ArchivePaginationWrapper}>
            <a
                href={previousPostsHref}
                className={classnames({
                    [classes.ArchivePaginationButton]: true,
                    [classes.ArchivePaginationButtonDisabled]: !hasPreviousPosts,
                })}
                data-direction="previous"
                aria-disabled={!hasPreviousPosts}
            >
                Previous Page
            </a>

            <a
                href={nextPostsHref}
                className={classnames({
                    [classes.ArchivePaginationButton]: true,
                    [classes.ArchivePaginationButtonDisabled]: !hasMorePosts,
                })}
                data-direction="next"
                aria-disabled={!hasMorePosts}
            >
                Next Page
            </a>
        </div>
    );
};

export default ArchivePagination;
