import { CSSProperties } from 'react';

/**
 * Generate a style block from attributes supplied from the attributesJSON parameter
 *
 * @param attributes
 */
export const styleBlockFromAttributes = (attributes?: Record<string, any>) => {
    /**
     * Early exit if nothing is defined
     */
    if (!attributes) {
        return undefined;
    }

    /**
     * Define the initial styleBlock object and type it to CSSProperties
     */
    let styleBlock: CSSProperties = {};

    /**
     * What the layout type should be, block, flex etc
     * Default: inline-block
     */
    if (attributes?.layout?.type) {
        const isValidDisplayType = ['block', 'inline-block', 'flex', 'inline-flex', 'grid', 'inline'].includes(
            attributes?.layout?.type,
        );
        styleBlock = {
            ...styleBlock,
            display: isValidDisplayType ? attributes?.layout?.type : null,
        };
    }

    /**
     * What the justify content should be
     * Default: flex-start
     */
    if (attributes?.layout?.justifyContent) {
        styleBlock = {
            ...styleBlock,
            justifyContent: attributes.layout.justifyContent ?? 'flex-start',
        };
    }

    /**
     * What the text alignment should be,
     * Default: left
     */
    if (attributes?.textAlign || attributes?.align) {
        let textAlignment: CSSProperties['textAlign'] = 'left';

        if (attributes?.textAlign) {
            textAlignment = attributes?.textAlign;
        }

        if (attributes?.align) {
            textAlignment = attributes?.align;
        }

        styleBlock = {
            ...styleBlock,
            textAlign: textAlignment,
        };
    }

    /**
     * What should the width be?
     * Default: N/A
     */
    if (attributes?.width) {
        // Store the value from the API
        let width = attributes?.width;
        // Check to see if the string contains a unit of measurement or not
        const containsUnitMeasurement = /%|px|rem|em/gm.test(attributes?.width);
        // Define a default unit measurement of px
        let fallbackUnitMeasurement = 'px';
        // If we have a button, set it to percentage instead to match WordPress
        if (attributes?.type === 'button') {
            fallbackUnitMeasurement = '%';
        }
        // If there is no measurement, lets construct a string with a measurement
        if (!containsUnitMeasurement) {
            width = `${width}${fallbackUnitMeasurement}`;
        }
        styleBlock = {
            ...styleBlock,
            width: `${width ?? 'auto'}`,
        };
    }

    /**
     * What should the height of the element be?
     * Default: N/A
     */
    if (attributes?.height) {
        // Store the value from the API
        let height = attributes?.height;
        // Check to see if the string contains a unit of measurement or not
        const containsUnitMeasurement = /%|px|rem|em/gm.test(attributes?.height);

        // Define a default unit measurement of px
        const fallbackUnitMeasurement = 'px';

        // If there is no measurement, lets construct a string with a measurement
        if (!containsUnitMeasurement) {
            height = `${height}${fallbackUnitMeasurement}`;
        }

        styleBlock = {
            ...styleBlock,
            height: `${height ?? 'auto'}`,
        };
    }

    /**
     * Does this element have an aspect ratio defined?
     */
    if (attributes?.aspectRatio) {
        styleBlock = {
            ...styleBlock,
            aspectRatio: `${attributes?.aspectRatio}`,
        };
    }

    /**
     * Should the image be cover or contained?
     */
    if (attributes?.scale) {
        styleBlock = {
            ...styleBlock,
            objectFit: attributes?.scale,
        };
    }

    /**
     * Sometimes components come with different styles in different places
     * This looks to handle background color and text color
     */
    if (attributes?.style?.color?.background || attributes?.style?.backgroundColor || attributes?.backgroundColor) {
        let backgroundColor: string | undefined;

        if (attributes?.style?.color?.background) {
            backgroundColor = attributes?.style?.color?.background;
        }

        if (attributes?.style?.backgroundColor) {
            backgroundColor = attributes?.style?.backgroundColor;
        }

        if (attributes?.backgroundColor) {
            backgroundColor = attributes?.backgroundColor;
        }

        styleBlock = {
            ...styleBlock,
            background: backgroundColor,
            padding: '10px', // Apply a small bit of padding to prevent items being edge to edge in a coloured background
        };
    }

    /**
     * Fix up text colours
     */
    if (attributes?.style?.textColor || attributes?.textColor) {
        let textColor: string | undefined;

        if (attributes?.style?.textColor) {
            textColor = attributes?.style?.textColor;
        }

        if (attributes?.textColor) {
            textColor = attributes?.textColor;
        }

        styleBlock = {
            ...styleBlock,
            color: textColor,
        };
    }

    /**
     * Has a border radius been defined?
     * Default: 5px
     */
    if (attributes?.style?.border?.radius || attributes?.className?.includes('is-style-rounded')) {
        let radius = attributes?.style?.border?.radius;

        if (radius?.topLeft) {
            styleBlock = {
                ...styleBlock,
                borderTopLeftRadius: radius?.topLeft ?? '5px',
            };
        }
        if (radius?.topRight) {
            styleBlock = {
                ...styleBlock,
                borderTopRightRadius: radius?.topRight ?? '5px',
            };
        }
        if (radius?.bottomLeft) {
            styleBlock = {
                ...styleBlock,
                borderBottomLeftRadius: radius?.bottomLeft ?? '5px',
            };
        }
        if (radius?.bottomRight) {
            styleBlock = {
                ...styleBlock,
                borderBottomRightRadius: radius?.bottomRight ?? '5px',
            };
        }

        if (attributes?.className?.includes('is-style-rounded')) {
            radius = '999px';
        }
        styleBlock = {
            ...styleBlock,
            borderRadius: radius ?? '5px',
        };
    }

    /**
     * Have we defined custom typography styles?
     */
    if (attributes?.style?.typography) {
        const typographyStyles = attributes?.style?.typography;

        if (typographyStyles?.fontSize) {
            styleBlock = {
                ...styleBlock,
                fontSize: typographyStyles?.fontSize,
                lineHeight: 1.618, // Apply a line height based on the Golden Ratio to prevent squashed text for any size
                wordBreak: 'break-word',
            };
        }

        if (typographyStyles?.textTransform) {
            styleBlock = {
                ...styleBlock,
                textTransform: typographyStyles?.textTransform,
            };
        }
    }

    /**
     * Handle styles for Media/Text blocks
     */
    if (attributes?.mediaWidth) {
        styleBlock = {
            ...styleBlock,
            display: 'grid',
        };
    }

    return styleBlock;
};

export default styleBlockFromAttributes;
