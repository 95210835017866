import React from 'react';
import { ApolloError } from 'apollo-client';

// import {NostoRow} from "src/components/Nosto/NostoRow";
import { SkeletonBlock } from 'src/components/Helpers/SkeletonBlock';
import { ErrorOrOfflineConnected } from 'src/components/Error/ErrorOrOffline';

import { CategoryList } from '../../components/CategoryList';
import { Header } from '../../components/Header';
import { PostListGrid } from '../../components/PostListGrid';
import { Yoast } from '../../components/Yoast';
import type { PostData, CategoryTax } from '../../types';

export type FrontpageUIProps = {
    isLoadingPosts?: boolean;
    isLoadingCategories?: boolean;
    isLoadingSiteData?: boolean;
    isErrorPosts?: ApolloError;
    isErrorCategories?: ApolloError;
    isErrorSiteData?: ApolloError;
    latestPosts?: PostData[];
    allCategories?: CategoryTax[];
    siteData: any;
    canonicalURL: string;
};

export const FrontpageUI = React.memo((props: FrontpageUIProps) => {
    if (props?.isErrorPosts || props?.isErrorCategories || props?.isErrorSiteData) {
        return <ErrorOrOfflineConnected />;
    }

    if (props.isLoadingPosts || props?.isLoadingCategories || props?.isLoadingSiteData) {
        return <SkeletonBlock />;
    }

    return (
        <>
            <Yoast
                postTitle={props?.siteData?.generalSettingsTitle}
                metaDesc={props.siteData?.generalSettingsDescription}
                opengraph={{
                    ogDescription: props.siteData?.generalSettingsDescription,
                    ogSiteName: props?.siteData?.generalSettingsTitle,
                }}
                canonicalUrl={props.canonicalURL}
            />

            <Header
                variant="frontpage"
                title={props.siteData?.generalSettingsTitle}
                subtitle={props.siteData?.generalSettingsDescription}
            />

            <CategoryList categories={props?.allCategories} title="" />

            <PostListGrid variant="frontpage" posts={props?.latestPosts} />

            <CategoryList variant="card" categories={props?.allCategories} title="Explore more" />
        </>
    );
});

export default FrontpageUI;
