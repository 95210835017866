import React from 'react';
import ReactPlayer from 'react-player';
import TweetEmbed from 'react-tweet-embed';
import { useQuery } from '@apollo/react-hooks';
import { TikTokEmbed } from 'react-social-media-embed';

import { useDeps } from 'src/hooks/useDeps';

import GetInstagramEmbed from '../../../queries/GetInstagramEmbed.graphql';

import classes from './Embed.module.scss';

export type EmbedProps = {
    variant: 'youtube' | 'twitter' | 'instagram' | 'tiktok' | 'video';
    postId?: number | string;
    url?: string;
    videoControls?: {
        caption?: string;
        autoplay?: boolean;
        loop?: boolean;
        muted?: boolean;
        controls?: boolean;
    };
};

export const InstagramEmbed = (props: { postId?: number | string }) => {
    const { SSR_GQL } = useDeps().env;

    const queryOptions = {
        ssr: SSR_GQL,
    };

    const { data, loading } = useQuery(GetInstagramEmbed, {
        ...queryOptions,
        variables: {
            postID: props?.postId,
        },
    });

    if (loading) return <p>Loading Instagram Post</p>;

    return <div dangerouslySetInnerHTML={{ __html: data?.instagramEmbed }} />;
};

export const Embed = (props: EmbedProps) => {
    let id;

    if (props?.variant == 'twitter') {
        id = props?.url?.match(/(^|[^'"])(https?:\/\/twitter\.com\/(?:#!\/)?(\w+)\/status(?:es)?\/(\d+))/);
    }

    return (
        <div className={classes.EmbedWrapper} data-variant={props?.variant}>
            {props?.variant == 'youtube' && (
                <ReactPlayer url={props?.url} width="100%" height="100%" className={classes.Player} controls={true} />
            )}

            {props?.variant == 'instagram' && <InstagramEmbed postId={props?.postId} />}

            {props?.variant == 'twitter' && <TweetEmbed tweetId={id[4]} placeholder="Tweet Loading" />}

            {props?.variant == 'video' && (
                <>
                    <ReactPlayer
                        url={props?.url}
                        className={classes.Player}
                        muted={props?.videoControls?.muted}
                        loop={props?.videoControls?.loop}
                        playing={props?.videoControls?.autoplay}
                        controls={props?.videoControls?.controls}
                        width="100%"
                        height="100%"
                    />
                    <span className={classes.EmbedCaption}>{props?.videoControls?.caption}</span>
                </>
            )}

            {props?.variant == 'tiktok' && (
                <TikTokEmbed placeholderDisabled={true} url={props?.url ? props?.url : ''} />
            )}
        </div>
    );
};
