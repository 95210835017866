import React from 'react';
import { Helmet } from 'react-helmet-async';
import { decode } from 'html-entities';

export type YoastProps = {
    canonicalUrl?: string;
    schema?: string;
    postTitle?: string;
    metaDesc?: string;
    metaKeywords?: string;
    focusKeywords?: string;
    opengraph?: {
        ogTitle?: string;
        ogType?: string;
        ogURL?: string;
        ogDescription?: string;
        ogSiteName?: string;
        ogArticlePublished?: string;
    };
};

export const Yoast = (props: YoastProps) => {
    const schema = () => {
        return {
            '@context': 'https://schema.org',
            '@type': 'BlogPosting',
            headline: `${props.postTitle}`,
            description: `${props.metaDesc}`,
            author: {
                '@type': 'Organization',
                name: "Woodie's",
            },
            datePublished: `${props?.opengraph?.ogArticlePublished}`,
        };
    };

    return (
        <Helmet titleTemplate="%s">
            <link rel="canonical" href={`https://${props.canonicalUrl}`} />
            {props?.postTitle && <title>{decode(props?.postTitle)}</title>}
            {props?.metaDesc && <meta data-meta-jh="" name="description" content={decode(props?.metaDesc)} />}
            {props?.metaKeywords && <meta name="keywords" content={props?.metaKeywords} data-jh />}
            {props?.opengraph?.ogTitle && <meta name="og:title" content={props?.opengraph?.ogTitle} />}
            {props?.opengraph?.ogType && <meta name="og:type" content={props?.opengraph?.ogType} />}
            {props?.opengraph?.ogDescription && (
                <meta name="og:description" content={decode(props?.opengraph?.ogDescription)} />
            )}
            {props?.opengraph?.ogSiteName && (
                <meta name="og:site_name" content={decode(props?.opengraph?.ogSiteName)} />
            )}
            {props?.opengraph?.ogArticlePublished && (
                <meta name="article:published_time" content={props?.opengraph?.ogArticlePublished} />
            )}

            {props?.schema && (
                <script
                    type="application/ld+json"
                    data-schema-jh="singlepost"
                    dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
                />
            )}
        </Helmet>
    );
};
