import React, { ReactNode } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client';
import { useLocation, useParams } from 'react-router';

import { useDeps } from 'src/hooks/useDeps';
import { ArchivePosts } from 'src/wordpress/types';

import GetPostsByCategory from '../../queries/GetPostsByCategory.graphql';
import { normalizeArchivePosts, normalizeArchiveData, normalizePageData } from '../../utils';

export type DefaultArchiveContextType = {
    posts: ArchivePosts[];
    archiveData: any;
    isLoading: boolean;
    error?: ApolloError;
    pageData?: any;
    postCount: number;
    archiveSlug?: string;
    currentPageNumber?: number;
    canonicalURL: string;
};

export const DefaultArchiveContext = {
    isLoading: true,
    posts: [],
    archiveData: {},
    error: undefined,
    pageData: undefined,
    fetchMore: () => {
        return '';
    },
    postCount: 10,
    archiveSlug: undefined,
    currentPageNumber: 1,
    canonicalURL: '',
};

export const ArchiveContext = React.createContext<DefaultArchiveContextType>(DefaultArchiveContext);

export type ArchiveProviderProps = {
    postSlug: string;
    children: ReactNode;
};

export const ArchiveProvider = (props: ArchiveProviderProps) => {
    const { SSR_GQL, DOMAIN } = useDeps().env;
    const { pagenumber } = useParams<{ pagenumber?: string }>();
    const location = useLocation();

    // Total number of Posts to fetch
    const TOTAL_POST_NUMBER = 9;

    /**
     * How many posts should we skip when fetching?
     * E.g.
     * - Total posts to show: 9
     * - Page number: 3
     * - Math = ((9 * 3) - 9) = 18 posts to skip
     * - Display 19 - 27
     */
    const OFFSET_POST_TOTAL =
        pagenumber !== undefined && Number(pagenumber) !== 1
            ? TOTAL_POST_NUMBER * Number(pagenumber) - TOTAL_POST_NUMBER
            : 0;

    const queryOptions = {
        ssr: SSR_GQL,
    };

    const {
        data: posts,
        error,
        loading,
    } = useQuery(GetPostsByCategory, {
        ...queryOptions,
        variables: {
            slug: props.postSlug ?? '',
            categoryName: props.postSlug ?? '',
            offset: OFFSET_POST_TOTAL,
            postsToFetch: TOTAL_POST_NUMBER,
        },
    });

    const api = {
        isLoading: loading,
        posts: normalizeArchivePosts(posts?.posts?.nodes) ?? undefined,
        archiveData: normalizeArchiveData(posts?.categories) ?? undefined,
        error: error,
        pageData: normalizePageData(posts?.posts?.pageInfo) ?? undefined,
        postCount: TOTAL_POST_NUMBER,
        archiveSlug: props?.postSlug ?? undefined,
        currentPageNumber: pagenumber ? Number(pagenumber) : 1,
        canonicalURL: DOMAIN + location?.pathname ?? '',
    };

    return <ArchiveContext.Provider value={api}>{props.children}</ArchiveContext.Provider>;
};
