import React from 'react';
import { decode } from 'html-entities';

import classes from './HeaderArchive.scss';

const LogoSrc = require('../../../../static/img/woodies-ideas-and-advice-logo.svg');

export type HeaderArchiveProps = {
    title?: string;
    subtitle?: string;
};

export const HeaderArchive = (props: HeaderArchiveProps) => {
    return (
        <div className={classes.root}>
            <div className={classes.text}>
                <a href="/community" className={classes.headerLogoLink}>
                    <img src={LogoSrc} alt="Woodie's Community" className={classes.headerLogo} />
                </a>
                <h1 className={classes.title}>{decode(props?.title)}</h1>
                <h2 className={classes.subtitle}>{decode(props.subtitle)}</h2>
            </div>
        </div>
    );
};

export default HeaderArchive;
