import React, { useContext } from 'react';

import { ArchiveUI } from './ArchiveUI';
import { ArchiveContext } from './ArchiveProvider';

export const ArchiveFeature = () => {
    const { posts, archiveData, isLoading, error, pageData, archiveSlug, postCount, canonicalURL, currentPageNumber } =
        useContext(ArchiveContext);

    return (
        <ArchiveUI
            isLoading={isLoading}
            posts={posts}
            archiveData={archiveData}
            error={error}
            pageData={pageData}
            archiveSlug={archiveSlug}
            postCount={postCount}
            currentPageNumber={currentPageNumber}
            canonicalURL={canonicalURL}
        />
    );
};

export default ArchiveFeature;
