import { v4 as uuidv4 } from 'uuid';

export const normalizeRichTextBlocks = (blocks?: Record<string, any>[]): any => {
    return blocks?.map((block) => ({
        blockUniqueKey: uuidv4(),
        ...block,
    }));
};

export default normalizeRichTextBlocks;
