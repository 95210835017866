import React from 'react';
import { ApolloError } from 'apollo-client';

import { ErrorOrOfflineConnected } from 'src/components/Error/ErrorOrOffline';
import { LoaderMinHeight } from 'src/components/Helpers/Skeleton';
// import {NostoRow} from "src/components/Nosto/NostoRow";
import { PostListGrid } from 'src/wordpress/components/PostListGrid';

import { Header } from '../../components/Header';
import { FeaturedImage } from '../../components/FeaturedImage';
import { RichText } from '../../components/RichText';
import { YoastBreadcrumbs } from '../../components/Breadcrumbs';
import { Yoast } from '../../components/Yoast';

export type SinglePostUIProps = {
    isLoading: boolean;
    postContent: any;
    error?: ApolloError;
    canonicalUrl: string;
};

export const SinglePostUI = (props: SinglePostUIProps) => {
    if (props.isLoading) {
        return <LoaderMinHeight />;
    }

    if (props?.error) {
        return <ErrorOrOfflineConnected />;
    }

    return (
        <>
            <Yoast
                canonicalUrl={props.canonicalUrl}
                schema={props?.postContent?.seo?.schema}
                postTitle={props?.postContent?.seo?.title}
                metaDesc={props?.postContent?.seo?.metaDesc}
                metaKeywords={props?.postContent?.seo?.metaKeywords}
                focusKeywords={props?.postContent?.seo?.focusKeywords}
                opengraph={{
                    ogTitle: props?.postContent?.seo?.opengraph?.ogTitle,
                    ogType: props?.postContent?.seo?.opengraph?.ogType,
                    ogURL: props?.postContent?.seo?.opengraph?.ogURL,
                    ogDescription: props?.postContent?.seo?.opengraph?.ogDescription,
                    ogSiteName: props?.postContent?.seo?.opengraph?.ogSiteName,
                    ogArticlePublished: props?.postContent?.seo?.opengraph?.ogArticlePublished,
                }}
            />

            <YoastBreadcrumbs breadcrumbs={props?.postContent?.seo?.breadcrumbs} />

            <Header
                variant="post"
                title={props?.postContent?.postTitle}
                postDate={props?.postContent?.postDate}
                postCategories={props?.postContent?.postCategories}
                displayDate={props?.postContent?.acfPostMeta?.displayDateOnArticle}
            />

            {props?.postContent?.featuredImage?.sizes && (
                <FeaturedImage
                    sizes={props?.postContent?.featuredImage?.sizes}
                    alt={props?.postContent?.featuredImage?.alt}
                    fullURL={props?.postContent?.featuredImage?.fullURL}
                    variant="post-hero"
                />
            )}

            <RichText content={props?.postContent?.postBlocks} postId={props?.postContent?.id} />

            <PostListGrid variant="related-posts" posts={props.postContent.acfPostMeta.relatedPosts} />
        </>
    );
};
