import React from 'react';

import { parseAttributes, styleBlockFromAttributes } from '../../utils';
import { RichText } from '../RichText';

import classes from './MediaText.module.scss';

export type MediaTextProps = {
    content?: string;
    attributes?: string;
    innerBlocks?: any;
};

export const MediaText = (props: MediaTextProps) => {
    const mediaTextAttrs = parseAttributes(props?.attributes);
    const mediaTextStyles = styleBlockFromAttributes(mediaTextAttrs);

    /**
     * Default setting for the media text columns
     */
    let mediaTextColumnStyles = `
        .${classes.mediatext__container} {
            grid-template-columns: ${mediaTextAttrs?.mediaWidth}% 1fr
        }
    `;

    /**
     * If the stacked on mobile option is true, we want to only apply the columns at a certain
     * breakpoint.
     */
    if (mediaTextAttrs?.isStackedOnMobile) {
        mediaTextColumnStyles = `
            .${classes.mediatext__container} {
                grid-template-columns: 1fr;
            }
        `;

        /**
         * Set the column widths depending on media position
         */
        if (mediaTextAttrs?.mediaPosition === 'left') {
            mediaTextColumnStyles =
                mediaTextColumnStyles +
                `
                @media only screen and (min-width: 600px) {
                    .${classes.mediatext__container} {
                        grid-template-columns: ${mediaTextAttrs?.mediaWidth}% 1fr;
                        grid-gap: 25px;
                    }
                }
            `;
        } else {
            mediaTextColumnStyles =
                mediaTextColumnStyles +
                `
                @media only screen and (min-width: 600px) {
                    .${classes.mediatext__container} {
                        grid-template-columns: 1fr ${mediaTextAttrs?.mediaWidth}%;
                        grid-gap: 25px;
                    }
                }
            `;
        }
    }

    return (
        <div className={classes.mediatext__container} style={mediaTextStyles}>
            <style dangerouslySetInnerHTML={{ __html: mediaTextColumnStyles }} />
            <div>
                <img src={mediaTextAttrs?.mediaUrl} alt={mediaTextAttrs?.mediaAlt} />
            </div>
            <div>
                <RichText content={props?.innerBlocks} />
            </div>
        </div>
    );
};

export default MediaText;
