/**
 * Use this utility to parse the JSON Attributes that are available on each block through the
 * attributeJSON parameter in a query
 *
 * @param attributes A string that contains a JSON object
 */
export const parseAttributes = (attributes?: string | null): Record<string, any> | undefined => {
    if (!attributes || attributes === '' || !JSON.parse(attributes)) {
        return undefined;
    }

    return JSON.parse(attributes);
};

export default parseAttributes;
