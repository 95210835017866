import React from 'react';
import { decode } from 'html-entities';
import classnames from 'classnames';

import { parseAttributes, styleBlockFromAttributes } from '../../utils';

import classes from './Heading.scss';

export type HeadingProps = {
    attributes?: string | null;
};

export const Heading = (props: HeadingProps) => {
    const headingAttrs = parseAttributes(props?.attributes);
    const headingStyles = styleBlockFromAttributes(headingAttrs);

    const title = headingAttrs?.content?.replace(/<[^>]*>\s*<\/[^>]*>/gs, '');

    const headingClasses = classnames(classes.title, classes.root);

    switch (headingAttrs?.level) {
        case 2:
            return (
                <h2 style={headingStyles} className={headingClasses}>
                    {decode(title)}
                </h2>
            );

        case 3:
            return (
                <h3 style={headingStyles} className={headingClasses}>
                    {decode(title)}
                </h3>
            );

        case 4:
            return (
                <h4 style={headingStyles} className={headingClasses}>
                    {decode(title)}
                </h4>
            );

        case 5:
            return (
                <h5 style={headingStyles} className={headingClasses}>
                    {decode(title)}
                </h5>
            );

        case 6:
            return (
                <h6 style={headingStyles} className={headingClasses}>
                    {decode(title)}
                </h6>
            );
        case 1:
        default:
            return (
                <h1 style={headingStyles} className={headingClasses}>
                    {decode(title)}
                </h1>
            );
    }
};
