import React from 'react';
import { decode } from 'html-entities';

import classes from './Quote.module.scss';

export type QuoteProps = {
    quote?: string;
    citation?: string;
};

export const Quote = (props: QuoteProps) => {
    let cleanedText = props?.quote?.replace('<p>', '');
    cleanedText = cleanedText?.replace('</p>', '');
    return (
        <div className={classes.quote__container}>
            {/*<div className={classes.quote__content}>*/}
            {/*    “*/}
            {/*    <div dangerouslySetInnerHTML={{__html: props?.quote}} />*/}
            {/*    ”*/}
            {/*</div>*/}
            <p className={classes.quote__content}>“{decode(cleanedText)}”</p>
            <h5 className={classes.quote__info}>- {decode(props?.citation)}</h5>
        </div>
    );
};

export default Quote;
