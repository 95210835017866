import React from 'react';

import { parseAttributes, styleBlockFromAttributes } from '../../utils';

import { SingleButton } from './SingleButton';
import classes from './Buttons.scss';

export type ButtonsProps = {
    attributes?: string;
    innerBlocks?: any[];
};

export const Buttons = (props: ButtonsProps) => {
    const buttonWrapperAttributes = parseAttributes(props?.attributes);
    const wrapperStyles = styleBlockFromAttributes(buttonWrapperAttributes);

    return (
        <div className={classes['root']}>
            <div style={wrapperStyles}>
                {props?.innerBlocks?.map((innerBlock, index) => {
                    return <SingleButton attributes={innerBlock.attributesJSON} key={`${innerBlock.name}-${index}`} />;
                })}
            </div>
        </div>
    );
};

export default Buttons;
