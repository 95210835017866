import React from 'react';

import classes from './YoastHowToBlock.module.scss';

export type YoastHowToBlockProps = {
    html: string;
};

export const YoastHowToBlock = (props: YoastHowToBlockProps) => {
    return <div className={classes.Wrapper} dangerouslySetInnerHTML={{ __html: props?.html }} />;
};

export default YoastHowToBlock;
