import React from 'react';

import { SingleCategory } from 'src/wordpress/types';

import { HeaderArchive, HeaderPost, HeaderFrontpage } from './';

export type Props = {
    variant: 'frontpage' | 'post' | 'archive' | 'page';
    title?: string;
    subtitle?: string;
    postDate?: string;
    postCategories?: SingleCategory[];
    displayDate?: boolean;
};

export const Header = (props: Props) => {
    if (props.variant === 'archive') {
        return <HeaderArchive title={props.title} subtitle={props.subtitle} />;
    }

    if (props.variant === 'post') {
        return (
            <HeaderPost
                title={props.title}
                date={props?.postDate}
                category={props?.postCategories}
                displayDate={props?.displayDate}
            />
        );
    }

    if (props.variant === 'frontpage') {
        return <HeaderFrontpage title={props.title} subtitle={props.subtitle} />;
    }

    return <HeaderArchive title={props.title} subtitle={props.subtitle} />;
};

Header.defaultProps = {
    variant: 'archive',
};

export default Header;
