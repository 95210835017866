import React, { ReactNode } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client';
import { useLocation } from 'react-router';

import { useDeps } from 'src/hooks/useDeps';

import type { PostData, CategoryTax } from '../../types';
import GetLatestPosts from '../../queries/GetLatestPosts.graphql';
import GetAllCategories from '../../queries/GetAllCategories.graphql';
import SiteData from '../../queries/SiteData.graphql';
import { normalizePostsArray } from '../../utils/normalizePostsArray';

export type contextType = {
    isLoadingPosts?: boolean;
    isLoadingCategories?: boolean;
    isLoadingSiteData?: boolean;
    isErrorPosts?: ApolloError;
    isErrorCategories?: ApolloError;
    isErrorSiteData?: ApolloError;
    latestPosts?: PostData[];
    allCategories?: CategoryTax[];
    siteData?: any;
    canonicalURL: string;
};

export const defaultContext = {
    isLoadingPosts: true,
    isLoadingCategories: true,
    isLoadingSiteData: true,
    isErrorPosts: undefined,
    isErrorCategories: undefined,
    isErrorSiteData: undefined,
    latestPosts: undefined,
    allCategories: [],
    siteData: [],
    canonicalURL: '',
};

export const FrontpageContext = React.createContext<contextType>(defaultContext);

export type FrontpageContextProviderProps = {
    children: ReactNode;
};

const normalizeCategories = (categories?: CategoryTax[]) => {
    return categories?.map((category) => {
        const imageSizes = category?.categoryMeta?.categoryImage?.mediaDetails?.sizes?.sort((a, b) => {
            return Number(a.width) - Number(b.width);
        });

        return {
            name: category?.name ?? undefined,
            slug: category?.slug ?? undefined,
            postCount: Number(category?.postCount) ?? undefined,
            categoryMeta: {
                categoryImage: {
                    altText: category?.categoryMeta?.categoryImage?.altText ?? undefined,
                    fullURL: category?.categoryMeta?.categoryImage?.fullURL ?? undefined,
                    mediaDetails: {
                        sizes: imageSizes ?? undefined,
                    },
                },
            },
        };
    });
};

export const FrontpageContextProvider = (props: FrontpageContextProviderProps) => {
    const { SSR_GQL, DOMAIN } = useDeps().env;
    const location = useLocation();

    const queryOptions = {
        ssr: SSR_GQL,
    };

    const { data: latestPosts, error: isErrorPosts, loading: isLoadingPosts } = useQuery(GetLatestPosts, queryOptions);

    const {
        data: allCategories,
        error: isErrorCategories,
        loading: isLoadingCategories,
    } = useQuery(GetAllCategories, queryOptions);

    const { data: siteData, error: isErrorSiteData, loading: isLoadingSiteData } = useQuery(SiteData, queryOptions);

    const curatedPostsOrNot =
        latestPosts?.themeOptions?.themeOptions?.homepagePosts !== null
            ? normalizePostsArray(latestPosts?.themeOptions?.themeOptions?.homepagePosts)
            : normalizePostsArray(latestPosts?.posts?.nodes);

    const api = {
        isLoadingPosts: isLoadingPosts ?? undefined,
        isLoadingCategories: isLoadingCategories ?? undefined,
        isLoadingSiteData: isLoadingSiteData ?? undefined,
        isErrorPosts: isErrorPosts,
        isErrorCategories: isErrorCategories,
        isErrorSiteData: isErrorSiteData,
        latestPosts: curatedPostsOrNot ?? undefined,
        allCategories: normalizeCategories(allCategories?.categories?.nodes) ?? undefined,
        siteData: siteData?.allSettings ?? undefined,
        canonicalURL: DOMAIN + location?.pathname ?? '',
    };

    return <FrontpageContext.Provider value={api}>{props.children}</FrontpageContext.Provider>;
};
