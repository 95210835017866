import React from 'react';
import { decode } from 'html-entities';

import classes from './HeaderArchive.scss';

const LogoSrc = require('../../../../static/img/woodies-ideas-and-advice-logo.svg');

export type HeaderFrontpageProps = {
    title?: string;
    subtitle?: string;
};

export const HeaderFrontpage = (props: HeaderFrontpageProps) => {
    return (
        <div className={classes.root}>
            <div className={classes.text}>
                <span className={classes.headerLogoLink}>
                    <img src={LogoSrc} alt="Woodie's Community" className={classes.headerLogo} />
                </span>
                <h2 className={classes.subtitle}>{decode(props.subtitle)}</h2>
            </div>
        </div>
    );
};

export default HeaderFrontpage;
