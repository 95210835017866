export const getSingleOptionValue = (option: string) => {
    if (option) {
        // Limit the return value to 2 items in the array
        return option?.split(/"(.*)"/, 2)?.[1];
    }

    return undefined;
};

export default getSingleOptionValue;
