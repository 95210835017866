import React from 'react';

export interface HubspotFormsProps {
    formID?: string;
    region?: string;
    portalID?: string;
    isHubspotFormsLoaded?: boolean;
}
export const HubspotForms = (props: HubspotFormsProps) => {
    if (props.isHubspotFormsLoaded && props.formID) {
        return (
            <div>
                <script
                    dangerouslySetInnerHTML={{
                        __html: `
                    hbspt.forms.create({
                        region: "${props.region ?? 'na1'}",
                        portalId: "${props.portalID ?? '8026331'}",
                        formId: "${props.formID ?? ''}"
                    });
                `,
                    }}
                ></script>
            </div>
        );
    }

    return null;
};
