import React from 'react';

import { HubspotForms, InlineProductsWrapper } from 'src/wordpress/components/RichText/Shortcodes/SingleShortcodes';

import {
    getShortcodeName,
    getShortcodeOptions,
    getSingleOptionValueAsDigitsOnly,
    getSingleOptionValue,
    getSingleOption,
} from './utils';

export interface ShortcodesProps {
    shortcode?: string;
    isHubspotFormsLoaded?: boolean;
}

export const Shortcodes = (props: ShortcodesProps) => {
    if (props?.shortcode) {
        const originalShortcode = props?.shortcode ?? '';
        const stringToFirstSpaceRegex = /\s(.+)/;
        const shortcodeNameAndOptions = originalShortcode.split(stringToFirstSpaceRegex, 2);

        // Ensure that we have two items to work from
        if (shortcodeNameAndOptions.length === 2) {
            const shortcodeName = getShortcodeName(shortcodeNameAndOptions[0]);
            const shortcodeOptions = getShortcodeOptions(shortcodeNameAndOptions[1]);

            // Convert to lowercase here so that we can always have the Raw Name if needed elsewhere
            switch (shortcodeName?.toLowerCase()) {
                case 'inlineproducts':
                case 'product':
                case 'products':
                    // Passing sku as sku will match with sku and skus
                    const skusOption = getSingleOption(shortcodeOptions, 'sku');

                    // Get the SKUs themselves by matching for digits, as the option is returned as skus="1,2,3"
                    const productSkus = getSingleOptionValueAsDigitsOnly(skusOption ?? '');

                    return <InlineProductsWrapper skus={productSkus ?? []} />;

                case 'hubspotform':
                    const formIDOption = getSingleOption(shortcodeOptions, 'formid');
                    const formID = getSingleOptionValue(formIDOption ?? '');

                    const regionOption = getSingleOption(shortcodeOptions, 'region');
                    const region = getSingleOptionValue(regionOption ?? '');

                    const portalIDOption = getSingleOption(shortcodeOptions, 'portalID');
                    const portalID = getSingleOptionValue(portalIDOption ?? '');

                    return (
                        <HubspotForms
                            isHubspotFormsLoaded={props?.isHubspotFormsLoaded}
                            formID={formID}
                            portalID={portalID}
                            region={region}
                        />
                    );

                default:
                    break;
            }
        }
    }

    return null;
};
