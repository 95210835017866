import React from 'react';
import { decode } from 'html-entities';

import { useCleanMediaUrl } from '../../../hooks';
import { imageComponentPropsFromAttributes, parseAttributes, styleBlockFromAttributes } from '../../utils';

import classes from './Image.module.scss';

export type ImageProps = {
    attributes?: any;
};

export const Image = (props: ImageProps) => {
    const imageAttrs = parseAttributes(props?.attributes);
    const { cleanMediaUrl } = useCleanMediaUrl(imageAttrs?.url);
    const imageStyles = styleBlockFromAttributes(imageAttrs);

    const attributes: any = {
        ...imageComponentPropsFromAttributes(imageAttrs, cleanMediaUrl),
        style: imageStyles,
    };

    return (
        <div className={classes.ImageWrapper} data-img-alignment={imageAttrs?.align ?? 'center'}>
            <div className={classes.ImageWrapperInner}>
                <img {...attributes} />
                {imageAttrs?.caption && (
                    <span
                        className={classes.ImageCaption}
                        style={{ textAlign: imageAttrs?.align ?? 'center' }} // Ensure caption aligns with image
                        dangerouslySetInnerHTML={{ __html: decode(imageAttrs?.caption) }}
                    /> // Use dangerouslySetInnerHTML to ensure that HTML tags are rendered from WP
                )}
            </div>
        </div>
    );
};
