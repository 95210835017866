import React from 'react';
import { Link } from 'react-router-dom';
import { decode } from 'html-entities';

import classes from 'src/components/Breadcrumbs/Breadcrumbs.scss';

export type YoastBreadcrumbsProps = {
    breadcrumbs: {
        text: string;
        url: string;
    }[];
};

export const YoastBreadcrumbs = (props: YoastBreadcrumbsProps) => {
    return (
        <div className={classes.root}>
            <div className="scroller">
                <ul className="yoastbreadcrumbs">
                    <li>
                        <Link to="/" title="Go to Home Page">
                            Home
                        </Link>
                    </li>
                    {props?.breadcrumbs?.map((breadcrumb, index) => {
                        // URL from Wordpress Yoast is absolute and not relative so we have to strip out the domain
                        // so we can use just the path in the breadcrumb
                        const url = new URL(breadcrumb?.url);
                        let path = url.pathname;
                        path = path.replace(/\/$/, '');
                        const lastItemTitle = props?.breadcrumbs[props?.breadcrumbs.length - 1];

                        // Don't make list item a link so that we don't self link
                        if (breadcrumb.text === lastItemTitle.text) {
                            return (
                                <li key={`${breadcrumb.text}-${index}`}>
                                    <span>{decode(breadcrumb?.text)}</span>
                                </li>
                            );
                        }

                        return (
                            <li key={`${breadcrumb.text}-${index}`}>
                                <Link to={`/community${path}`} title={`Go to ${breadcrumb?.text}`}>
                                    <span>{decode(breadcrumb?.text)}</span>
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default YoastBreadcrumbs;
