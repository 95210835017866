import React from 'react';
import { useParams, useLocation } from 'react-router';

import { useDeps } from 'src/hooks/useDeps';
import { SinglePostProvider } from 'src/wordpress/features/SinglePost';
import { SinglePostFeature } from 'src/wordpress/features/SinglePost';

export const DefaultPost = () => {
    const {
        env: { DOMAIN },
    } = useDeps();

    const location = useLocation();
    const { slug } = useParams() as Record<string, any>;

    return (
        <SinglePostProvider postSlug={slug}>
            <SinglePostFeature canonicalUrl={DOMAIN + location?.pathname} />
        </SinglePostProvider>
    );
};

export default DefaultPost;
