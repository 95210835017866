import React from 'react';

import { YoastHowToBlock } from '../Yoast';
import { normalizeRichTextBlocks } from '../utils';

import { Gallery } from './Gallery';
import { ParagraphDefault } from './Paragraph';
import { Heading } from './Heading';
import { List } from './List';
import { Embed } from './Embed';
import { Shortcodes } from './Shortcodes';
import { Table } from './Table';
import { Image } from './Image';
import { Quote } from './Quote';
import { MediaText } from './MediaText';
import { Columns } from './Columns';
import { Buttons, SingleButton } from './Buttons';
import { Group } from './Group';
import { Spacer } from './Spacer';
import classes from './RichText.module.scss';
import { useHubspotForms } from './Shortcodes/hooks';

export type RichTextProps = {
    content?: any[];
    postId?: number | string;
};

export const RichText = (props: RichTextProps) => {
    const richTextBlocks = normalizeRichTextBlocks(props?.content);
    const { isHubspotFormsScriptLoaded } = useHubspotForms();
    return (
        <div className={classes['Content_Wrapper']}>
            {richTextBlocks?.map((block) => {
                const { blockUniqueKey, ...blockProps } = block;

                switch (blockProps.__typename) {
                    case 'CoreParagraphBlock':
                        return <ParagraphDefault attributesJSON={blockProps?.attributesJSON} key={blockUniqueKey} />;

                    case 'CoreHeadingBlock':
                        return <Heading attributes={blockProps?.attributesJSON} key={blockUniqueKey} />;

                    case 'CoreGalleryBlock':
                        return (
                            <Gallery
                                attributes={blockProps?.attributesJSON}
                                images={blockProps?.innerBlocks}
                                key={block.blockUniqueKey}
                            />
                        );

                    case 'CoreEmbedBlock':
                        return (
                            <Embed
                                variant={block?.attributes?.providerNameSlug}
                                url={block?.attributes?.url}
                                key={block.blockUniqueKey}
                                postId={props?.postId}
                            />
                        );

                    case 'CoreVideoBlock':
                        return (
                            <Embed
                                variant="video"
                                url={block?.attributes?.src}
                                videoControls={{
                                    controls: block?.attributes?.controls ?? undefined,
                                    autoplay: block?.attributes?.autoplay ?? undefined,
                                    loop: block?.attributes?.loop ?? undefined,
                                    muted: block?.attributes?.muted ?? undefined,
                                    caption: block?.attributes?.caption ?? undefined,
                                }}
                                key={block.blockUniqueKey}
                            />
                        );

                    case 'CoreListBlock':
                        return (
                            <List
                                attributes={block?.attributesJSON}
                                innerBlocks={block?.innerBlocks}
                                variant={block?.attributes?.ordered ? 'ordered' : 'unordered'}
                                key={block.blockUniqueKey}
                            />
                        );

                    case 'CoreShortcodeBlock':
                        return (
                            <Shortcodes
                                shortcode={block?.originalContent}
                                key={block?.blockUniqueKey}
                                isHubspotFormsLoaded={isHubspotFormsScriptLoaded}
                            />
                        );

                    case 'CoreImageBlock':
                        return <Image attributes={block?.attributesJSON} key={block.blockUniqueKey} />;

                    case 'CoreTableBlock':
                        return <Table tableData={block?.attributes?.body} key={block.blockUniqueKey} />;

                    case 'CoreQuoteBlock':
                        return (
                            <Quote
                                quote={block?.attributes?.value}
                                citation={block?.attributes?.citation}
                                key={block.blockUniqueKey}
                            />
                        );

                    case 'CoreMediaTextBlock':
                        return (
                            <MediaText
                                innerBlocks={block?.innerBlocks}
                                attributes={block?.attributesJSON}
                                content={block.saveContent}
                                key={block.blockUniqueKey}
                            />
                        );

                    case 'yoast/how-to-block':
                        return <YoastHowToBlock html={block?.saveContent} key={block.blockUniqueKey} />;

                    case 'CoreColumnsBlock':
                        return (
                            <Columns
                                totalColumns={block.innerBlocks?.length}
                                innerBlocks={block.innerBlocks}
                                attributes={block?.attributesJSON}
                                key={block.blockUniqueKey}
                            />
                        );

                    case 'CoreButtonsBlock':
                        return (
                            <Buttons
                                attributes={block?.attributesJSON}
                                innerBlocks={block?.innerBlocks}
                                key={block.blockUniqueKey}
                            />
                        );

                    case 'core/button':
                        return <SingleButton attributes={block?.attributesJSON} key={block.blockUniqueKey} />;

                    case 'CoreGroupBlock':
                        return (
                            <Group
                                attributes={block?.attributesJSON}
                                innerBlocks={block?.innerBlocks}
                                key={block.blockUniqueKey}
                            />
                        );

                    case 'CoreSpacerBlock':
                        return <Spacer attributes={block?.attributesJSON} key={block.blockUniqueKey} />;
                }
            })}
        </div>
    );
};

export default RichText;
