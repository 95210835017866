import React, { useEffect } from 'react';
import { useSetResolved } from '@wearejh/m2-pwa-engine/lib/router';
import { DispatchProp } from 'react-redux';
import { ApolloProvider } from '@apollo/react-hooks';
import { AsyncSwitch } from '@wearejh/m2-pwa-engine/lib/router';
import { Route } from 'react-router';

import { Container, Wrapper } from 'src/components/Layout';
import { useDeps } from 'src/hooks/useDeps';
import { useGetJWT } from 'src/wordpress/hooks';
import { fireGTMEvent } from 'src/features/analytics/hooks/useGtm';

import '../../scss/_root-component.scss';
import { DefaultPost, DefaultArchive, Frontpage } from '../../wordpress/templates';
import useWordpressClient from '../../clients/useWordpressClient';

type CommunityPageProps = {
    id: number;
    outdated: boolean;
    version: string | undefined;
} & DispatchProp<any>;

export const CommunityRoot: React.FC<CommunityPageProps> = () => {
    const { paths } = useDeps();
    const { WP_USER, WP_APP_KEY } = useDeps().env;
    const { getToken, refreshToken } = useGetJWT();
    const { WordpressClient } = useWordpressClient();
    useSetResolved();

    useEffect(() => {
        fireGTMEvent({ event: 'pageview', pageType: 'Community Page' });
    }, []);

    useEffect(() => {
        const currentDate = new Date();
        const currentDatePlusTwentyMins = new Date(currentDate.getTime() + 200 * 6000);

        const tokenExpiry = localStorage.getItem('wp_token_expires');

        const mutationVariables = {
            username: WP_USER,
            password: WP_APP_KEY,
        };

        // Exit early if the token expiry time is less than 20 minutes from now.
        if (tokenExpiry != null && currentDate.toLocaleString() <= tokenExpiry) return undefined;

        // Fetch the token from the mutation
        getToken({
            variables: {
                ...mutationVariables,
            },
        }).then((res) => {
            // Set the data
            const { data } = res;

            // If the current time is greater than token expiry we want to fetch another token
            if (tokenExpiry !== null && tokenExpiry < currentDate.toLocaleString()) {
                // Update the token with a refreshed Token
                refreshToken({
                    variables: {
                        jwtRefreshToken: data.login.refreshToken,
                    },
                }).then((res) => {
                    const { data: newData } = res;
                    localStorage.setItem('wp_token', newData.login.authToken);
                });

                // Reset the token expire length to the current time + 20 minutes
                localStorage.setItem('wp_token_expires', currentDatePlusTwentyMins.toLocaleString());
            } else {
                // Otherwise we want to just set a token and expiry date.
                localStorage.setItem('wp_token', data.login.authToken);
                localStorage.setItem('wp_token_expires', currentDatePlusTwentyMins.toLocaleString());
            }
        });
    }, [getToken, refreshToken, WP_USER, WP_APP_KEY]);

    return (
        <ApolloProvider client={WordpressClient}>
            <Wrapper variant="page-inner">
                <Container>
                    <AsyncSwitch base="/community">
                        <Route exact path={paths.community.singlePost}>
                            <DefaultPost />
                        </Route>
                        <Route exact path={paths.community.categoryArchivePaged}>
                            <DefaultArchive />
                        </Route>
                        <Route exact path={paths.community.categoryArchive}>
                            <DefaultArchive />
                        </Route>
                        <Route>
                            <Frontpage />
                        </Route>
                    </AsyncSwitch>
                </Container>
            </Wrapper>
        </ApolloProvider>
    );
};

export default CommunityRoot;
