import React from 'react';

import { parseAttributes, styleBlockFromAttributes } from 'src/wordpress/components/utils';

export type SpacerProps = {
    attributes: string;
};

export const Spacer = (props: SpacerProps) => {
    const spacerAttributes = parseAttributes(props?.attributes);
    const wrapperStyles = styleBlockFromAttributes(spacerAttributes);

    return <div style={wrapperStyles}></div>;
};

export default Spacer;
