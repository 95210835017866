import { useState, useEffect } from 'react';

export const useHubspotForms = () => {
    const [hubspotFormsScriptStatus, setHubspotFormsScriptStatus] = useState({ isHubspotFormsScriptLoaded: false });

    useEffect(() => {
        // Create the Hubspot Forms script element
        const script = document.createElement('script');

        // Setup attributes
        script.src = `//js.hsforms.net/forms/embed/v2.js`;
        script.async = true;
        script.type = 'text/javascript';
        script.onload = () => {
            // Define it in our state if loaded
            setHubspotFormsScriptStatus({
                isHubspotFormsScriptLoaded: true,
            });
        };

        // Append to the document
        document.head.appendChild(script);

        // Remove the script
        return () => {
            document.head.removeChild(script);
        };
    }, []);

    // Remove everything
    return hubspotFormsScriptStatus;
};

export default useHubspotForms;
