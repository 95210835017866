import { HTMLProps } from 'react';

/**
 * Any props that may appear in any HTML element
 * @param attrs
 */
const defaultComponentProps = (attrs) => {
    let defaultProps = {};

    /**
     * Set the title of the element if defined
     */
    if (attrs?.title) {
        defaultProps = {
            ...defaultProps,
            title: attrs?.title,
        };
    }

    /**
     * Use the anchor as the id of the element.
     * This could lead to duplicate IDs on the page as the data comes from the API
     */
    if (attrs?.anchor) {
        defaultProps = {
            ...defaultProps,
            id: attrs?.anchor,
        };
    }

    return defaultProps;
};

/**
 * Props specific to anchor elements
 * @param attrs
 */
export const anchorComponentPropsFromAttributes = (attrs): HTMLProps<HTMLAnchorElement> => {
    let anchorProps: HTMLProps<HTMLAnchorElement> = {};

    if (attrs?.url) {
        anchorProps = {
            ...anchorProps,
            href: attrs?.url,
        };
    }

    if (attrs?.target) {
        anchorProps = {
            ...anchorProps,
            target: attrs?.linkTarget,
        };
    }

    if (attrs?.rel) {
        anchorProps = {
            ...anchorProps,
            rel: attrs?.rel,
        };
    }

    anchorProps = {
        ...anchorProps,
        ...defaultComponentProps(attrs),
    };

    return anchorProps;
};

export const imageComponentPropsFromAttributes = (attrs, url: string): any => {
    let imageProps: any = {};

    /**
     * Define the URL of the image
     * This gets passed in separately, because we use a cleaning hook in the component
     * to convert the URL to a proxied version.
     */
    if (url) {
        imageProps = {
            ...imageProps,
            src: url,
        };
    }

    /**
     * What is the image alt text?
     */
    if (attrs?.alt) {
        imageProps = {
            ...imageProps,
            alt: attrs?.alt,
        };
    }

    imageProps = {
        ...imageProps,
        ...defaultComponentProps(attrs),
    };

    return imageProps;
};
