import React, { CSSProperties, HTMLProps, ReactNode } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { parseAttributes, styleBlockFromAttributes } from '../../utils';

import classes from './List.module.scss';

type ListProps = {
    variant: 'ordered' | 'unordered';
    attributes?: string;
    innerBlocks?: any[];
};

type ListWrapperProps = HTMLProps<HTMLElement> & {
    ordered: boolean;
    children?: ReactNode;
};

type ListItemProps = {
    itemContent?: string;
    itemStyles?: CSSProperties;
};

const ListWrapper = (props: ListWrapperProps) => {
    const List = props.ordered ? 'ol' : 'ul';
    return <List style={props.style}>{props.children}</List>;
};

const ListItem = (props: ListItemProps) => {
    if (props?.itemContent) {
        return <li style={props?.itemStyles} dangerouslySetInnerHTML={{ __html: props?.itemContent ?? '' }} />;
    }

    return null;
};

export const List = (props: ListProps) => {
    const listAttributes = parseAttributes(props?.attributes);
    const listStyles = styleBlockFromAttributes(listAttributes);
    const orderedList = listAttributes?.ordered === true;

    const innerBlocks = props.innerBlocks?.map((innerBlock) => {
        return {
            blockUniqueKey: uuidv4(),
            ...innerBlock,
        };
    });

    return (
        <div className={`${classes.root} ${classes.list}`} data-variant={orderedList ? 'ordered' : 'unordered'}>
            <ListWrapper ordered={orderedList} style={listStyles}>
                {innerBlocks?.map((innerBlock) => {
                    const listItemAttributes = parseAttributes(innerBlock?.attributesJSON);
                    const listItemStyles = styleBlockFromAttributes(listItemAttributes);

                    return (
                        <ListItem
                            itemStyles={listItemStyles}
                            itemContent={listItemAttributes?.content}
                            key={innerBlock.blockUniqueKey}
                        />
                    );
                })}
            </ListWrapper>
        </div>
    );
};

List.defaultProps = {
    variant: 'unordered',
};

export default List;
