import React, { ReactNode } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client';

import { useDeps } from 'src/hooks/useDeps';

import GetSinglePost from '../../queries/GetSinglePost.graphql';
import { normalizeSinglePost } from '../../utils';

export type DefaultSinglePostContextType = {
    postContent: any;
    error?: ApolloError;
    isLoading: boolean;
};

export const DefaultSinglePostContext = {
    isLoading: true,
    postContent: [],
    error: undefined,
};

export const SinglePostContext = React.createContext<DefaultSinglePostContextType>(DefaultSinglePostContext);

export type SinglePostProviderProps = {
    postSlug: string;
    children: ReactNode;
};

export const SinglePostProvider = (props: SinglePostProviderProps) => {
    const { SSR_GQL } = useDeps().env;

    const queryOptions = {
        ssr: SSR_GQL,
    };

    const {
        data: postContent,
        error,
        loading,
    } = useQuery(GetSinglePost, {
        variables: {
            slug: props.postSlug ?? '',
        },
        ...queryOptions,
    });

    const api = {
        isLoading: loading,
        error: error,
        postContent: normalizeSinglePost(postContent?.postBy) ?? undefined,
    };

    return <SinglePostContext.Provider value={api}>{props.children}</SinglePostContext.Provider>;
};
