import React, { HTMLProps } from 'react';

import {
    anchorComponentPropsFromAttributes,
    parseAttributes,
    styleBlockFromAttributes,
} from 'src/wordpress/components/utils';

import classes from './Buttons.scss';

export type SingleButtonProps = {
    attributes?: string;
    innerBlocks?: any[];
};

export const SingleButton = (props: SingleButtonProps) => {
    const singleButtonAttributes = parseAttributes(props?.attributes);
    const buttonStyles = styleBlockFromAttributes(singleButtonAttributes);

    const attributes: HTMLProps<HTMLAnchorElement> = {
        ...anchorComponentPropsFromAttributes(singleButtonAttributes),
        className: `button ${classes['singleButton']}`,
        style: { ...buttonStyles, display: 'inline-flex', alignItems: 'center', justifyContent: 'center' },
    };

    return <a {...attributes} dangerouslySetInnerHTML={{ __html: singleButtonAttributes?.text ?? '' }} />;
};

export default SingleButton;
