import React from 'react';
import { decode } from 'html-entities';

import { imageComponentPropsFromAttributes, parseAttributes, styleBlockFromAttributes } from '../../utils';
import { useCleanMediaUrl } from '../../../hooks';

import classes from './Gallery.module.scss';

export type GalleryProps = {
    attributes?: string | null;
    images?: {
        name?: string;
        attributes?: {
            url?: string;
            alt?: string;
            caption?: string;
        };
    }[];
};

const SingleGalleryImage = (props: { image: any }) => {
    const { cleanMediaUrl } = useCleanMediaUrl(props?.image?.attributes?.url);
    const galleryImageAttrs = parseAttributes(props?.image?.attributesJSON);
    const galleryImageStyles = styleBlockFromAttributes(galleryImageAttrs);

    const attr: any = {
        ...imageComponentPropsFromAttributes(galleryImageAttrs, cleanMediaUrl),
        style: galleryImageStyles,
    };

    return (
        <figure>
            <img {...attr} />
            <figcaption
                className={classes['GalleryImageCaption']}
                dangerouslySetInnerHTML={{ __html: decode(galleryImageAttrs?.caption) }}
            />
        </figure>
    );
};

export const Gallery = (props: GalleryProps) => {
    const galleryAttrs = parseAttributes(props?.attributes);
    const galleryStyles = styleBlockFromAttributes(galleryAttrs);

    return (
        <div className={classes.GalleryWrapper} style={galleryStyles}>
            {props?.images?.map((image, index) => {
                return <SingleGalleryImage image={image} key={`InnerBlock-${image?.name}-${index}`} />;
            })}
        </div>
    );
};

export default Gallery;
