import React from 'react';

import { parseAttributes, styleBlockFromAttributes } from '../../utils';
import { RichText } from '../RichText';

import classes from './Group.module.scss';

export type GroupProps = {
    attributes?: any;
    innerBlocks?: any;
};
export const Group = (props: GroupProps) => {
    const GroupWrapperAttributes = parseAttributes(props?.attributes);
    const wrapperStyles = styleBlockFromAttributes(GroupWrapperAttributes);

    return (
        <div className={classes['GroupComponent']} style={wrapperStyles}>
            <RichText content={props?.innerBlocks ?? []} />
        </div>
    );
};
