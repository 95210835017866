import React from 'react';
import { useContext } from 'react';

import { SinglePostUI } from 'src/wordpress/features/SinglePost/SinglePostUI';
import { SinglePostContext } from 'src/wordpress/features/SinglePost/SinglePostProvider';

type SinglePostFeatureProps = {
    canonicalUrl: string;
};

export const SinglePostFeature = ({ canonicalUrl }: SinglePostFeatureProps) => {
    const { postContent, isLoading, error } = useContext(SinglePostContext);

    return <SinglePostUI isLoading={isLoading} postContent={postContent} error={error} canonicalUrl={canonicalUrl} />;
};
