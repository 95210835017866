import React from 'react';
import { decode } from 'html-entities';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import { FeaturedImage } from 'src/wordpress/components/FeaturedImage';

import type { CategoryTax } from '../../types';

import classes from './CategoryList.module.scss';

export type CategoryListProps = {
    categories?: CategoryTax[];
    variant: 'simple' | 'card';
    title?: string;
};

export const CategoryList = (props: CategoryListProps) => {
    return (
        <div className={classes.CategoryList} data-variant={props.variant}>
            {props?.title && <p className={classes.CategoryList__Title}>{decode(props.title)}</p>}

            {props?.categories?.map((category, index) => {
                if (category?.postCount) {
                    return (
                        <Link
                            key={`${category.name}-${index}`}
                            to={'/community/category/' + category.slug}
                            className={classnames({
                                [classes.CategoryList__Link]: props?.variant === 'simple',
                                [classes.CategoryList__Card]: props?.variant === 'card',
                            })}
                        >
                            {props.variant === 'card' && (
                                <>
                                    <FeaturedImage
                                        sizes={category?.categoryMeta?.categoryImage?.mediaDetails?.sizes}
                                        alt={category?.categoryMeta?.categoryImage?.altText}
                                        fullURL={category?.categoryMeta?.categoryImage?.fullURL}
                                        variant="category-card"
                                    />
                                    <h3 className={classes.CategoryList__Category}>{decode(category.name)}</h3>
                                </>
                            )}

                            {props.variant === 'simple' && <span>{decode(category.name)}</span>}
                        </Link>
                    );
                }

                return null;
            })}
        </div>
    );
};

CategoryList.defaultProps = {
    variant: 'simple',
};

export default CategoryList;
