import React from 'react';
import { decode } from 'html-entities';

import classes from './Table.module.scss';

export type TableProps = {
    tableData?: {
        cells: {
            content: string;
        }[];
    }[];
};

export const Table = (props: TableProps) => {
    return (
        <div className={classes.TableWrapper}>
            <table>
                {props?.tableData?.map((row, index) => {
                    return (
                        <tr key={`${index}`}>
                            {row?.cells?.map((cell, index) => {
                                return (
                                    <td
                                        key={`${cell.content}-${index}`}
                                        dangerouslySetInnerHTML={{ __html: decode(cell?.content) }}
                                    />
                                );
                            })}
                        </tr>
                    );
                })}
            </table>
        </div>
    );
};

export default Table;
