import React from 'react';
import { decode } from 'html-entities';

import { parseAttributes, styleBlockFromAttributes } from '../../utils';

import classes from './ParagraphDefault.scss';

export type ParagraphProps = {
    attributesJSON?: string | null;
};

export const ParagraphDefault = (props: ParagraphProps) => {
    const paragraphAttrs = parseAttributes(props?.attributesJSON);
    const paragraphStyles = styleBlockFromAttributes(paragraphAttrs);

    return (
        <p
            style={paragraphStyles}
            className={classes.text}
            dangerouslySetInnerHTML={{ __html: decode(paragraphAttrs?.content) }}
        />
    );
};

export default ParagraphDefault;
